import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';

import { ComponentsModule } from './components/components.module';
import { getHasTriedToAuthenticate } from './redux/app.selectors';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, ComponentsModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  hasTriedToAuthenticate$ = this.store.select(getHasTriedToAuthenticate);

  constructor(private store: Store) {}
}
