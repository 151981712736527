import { createReducer, on } from '@ngrx/store';
import { DateTime } from 'luxon';

import { todoAdapter } from '../types/todo-model.type';
import {
  clearAllCompletedTodos,
  incomingTodoCreatedStreamEvent,
  incomingTodoDeletedStreamEvent,
  incomingTodoUpdatedStreamEvent,
  loadManyTodos,
  savedMe,
  saveMe,
  saveTodoItem,
  setMe,
  trashTodoItem,
  updateTodoItem,
} from './app.actions';
import { INITIAL_APP_STATE } from './app.state';

export const appReducer = createReducer(
  INITIAL_APP_STATE,
  on(saveTodoItem, (state, { todo }) => ({
    ...state,
    todos: todoAdapter.addOne(todo, state.todos),
  })),
  on(updateTodoItem, (state, { id, changes }) => ({
    ...state,
    todos: todoAdapter.updateOne(
      {
        id,
        changes: {
          updated: DateTime.utc().toISO() as string,
          ...changes,
        },
      },
      state.todos,
    ),
  })),
  on(loadManyTodos, (state, { todos }) => ({
    ...state,
    todos: todoAdapter.setAll(todos, state.todos),
  })),
  on(clearAllCompletedTodos, state => ({
    ...state,
    todos: todoAdapter.setAll([], state.todos),
  })),
  on(trashTodoItem, (state, { id }) => ({
    ...state,
    todos: todoAdapter.removeOne(id, state.todos),
  })),
  on(incomingTodoCreatedStreamEvent, (state, { event: { payload: todo } }) => ({
    ...state,
    todos: todoAdapter.addOne(todo, state.todos),
  })),
  on(incomingTodoUpdatedStreamEvent, (state, { event: { payload: todo } }) => ({
    ...state,
    todos: todoAdapter.updateOne(
      {
        id: todo.id,
        changes: todo,
      },
      state.todos,
    ),
  })),
  on(incomingTodoDeletedStreamEvent, (state, { event: { payload: todo } }) => ({
    ...state,
    todos: todoAdapter.removeOne(todo.id, state.todos),
  })),
  on(setMe, (state, { me }) => ({
    ...state,
    me,
    hasTriedToAuthenticate: true,
  })),
  on(saveMe, state => ({
    ...state,
    isSavingProfile: true,
  })),
  on(savedMe, (state, { me }) => ({
    ...state,
    isSavingProfile: false,
    me,
  })),
);
