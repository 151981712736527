import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as Sentry from '@sentry/angular-ivy';
import { DateTime } from 'luxon';
import { delay, map, switchMap, tap } from 'rxjs';
import { v4 } from 'uuid';

import {
  createAllTodosRequestStreamEvent,
  createCreateTodoStreamEvent,
  createDeleteTodoStreamEvent,
  createUpdateTodoStreamEvent,
} from '../helpers/stream-events';
import { ApiService } from '../services/api.service';
import { LoggerService } from '../services/logger.service';
import { StreamService } from '../services/stream.service';
import { TodoModel } from '../types/todo-model.type';
import {
  completeTodoItem,
  createTodoItem,
  incomingAllTodosResponseStreamEvent,
  incomingNoopStreamEvent,
  incomingStreamEvent,
  incomingTodoCreatedStreamEvent,
  incomingTodoDeletedStreamEvent,
  incomingTodoUpdatedStreamEvent,
  loadManyTodos,
  loadTodosFromStorage,
  savedMe,
  saveMe,
  saveTodoItem,
  startApp,
  trashTodoItem,
  triggerAuthentication,
  undoCompleteTodoItem,
  updateTodoItem,
} from './app.actions';

@Injectable({ providedIn: 'root' })
export class AppEffects {
  startApp$ = createEffect(() =>
    this.actions.pipe(
      ofType(startApp),
      switchMap(() => this.apiService.me()),
      tap(profile =>
        Sentry.setUser({
          id: profile?.userId,
          email: profile?.email,
          username: profile?.nickname,
        }),
      ),
      tap(profile => {
        try {
          const query = new URLSearchParams(location.search);

          if (query.has('status')) {
            const status = query.get('status');

            if (status === 'created') {
              plausible('signup', {
                props: {
                  userId: profile?.userId,
                  provider: profile?.idToken?.sub?.split('|')[0],
                },
              });
            }
          }
        } catch (e) {
          console.error(e);
        }
      }),
      tap(() => this.streamService.listen()),
      map(() => loadTodosFromStorage()),
    ),
  );

  createTodoItem$ = createEffect(() =>
    this.actions.pipe(
      ofType(createTodoItem),
      map(
        ({ content }) =>
          ({
            id: v4(),
            content,
            created: DateTime.utc().toISO(),
            updated: DateTime.utc().toISO(),
            completed: false,
          }) as TodoModel,
      ),
      tap(todo => this.streamService.send(createCreateTodoStreamEvent(todo))),
      map(todo => saveTodoItem({ todo })),
    ),
  );

  updateTodoItem$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(updateTodoItem),
        tap(({ id, changes }) =>
          this.streamService.send(createUpdateTodoStreamEvent({ id, changes })),
        ),
      ),
    { dispatch: false },
  );

  trashTodoItem$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(trashTodoItem),
        tap(({ id }) =>
          this.streamService.send(createDeleteTodoStreamEvent({ id })),
        ),
      ),
    { dispatch: false },
  );

  completeTodoItem$ = createEffect(() =>
    this.actions.pipe(
      ofType(completeTodoItem),
      map(({ id }) => updateTodoItem({ id, changes: { completed: true } })),
    ),
  );

  undoCompleteTodoItem$ = createEffect(() =>
    this.actions.pipe(
      ofType(undoCompleteTodoItem),
      map(({ id }) => updateTodoItem({ id, changes: { completed: false } })),
    ),
  );

  loadTodosFromStorage$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(loadTodosFromStorage),
        tap(() => this.streamService.send(createAllTodosRequestStreamEvent())),
      ),
    { dispatch: false },
  );

  incomingStreamEvent$ = createEffect(() =>
    this.actions.pipe(
      ofType(incomingStreamEvent),
      tap(({ event }) =>
        this.loggerService.info(`[StreamService] Event`, event),
      ),
      map(({ event }) => {
        switch (event.type) {
          case 'ALL_TODOS_RESPONSE':
            return incomingAllTodosResponseStreamEvent({ event });
          case 'TODO_CREATED':
            return incomingTodoCreatedStreamEvent({ event });
          case 'TODO_UPDATED':
            return incomingTodoUpdatedStreamEvent({ event });
          case 'TODO_DELETED':
            return incomingTodoDeletedStreamEvent({ event });
          default:
            return incomingNoopStreamEvent({ event });
        }
      }),
    ),
  );

  incomingAllTodosResponseStreamEvent$ = createEffect(() =>
    this.actions.pipe(
      ofType(incomingAllTodosResponseStreamEvent),
      map(({ event: { payload: todos } }) => loadManyTodos({ todos })),
    ),
  );

  triggerAuthentication$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(triggerAuthentication),
        tap(() => location.assign('/api/auth/authorize')),
      ),
    { dispatch: false },
  );

  saveMe$ = createEffect(() =>
    this.actions.pipe(
      ofType(saveMe),
      switchMap(({ name }) => this.apiService.saveMe({ name })),
      delay(1000),
      map(me => savedMe({ me })),
    ),
  );

  constructor(
    private actions: Actions,
    private streamService: StreamService,
    private apiService: ApiService,
    private loggerService: LoggerService,
  ) {}
}
