import { isDevMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular-ivy';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

declare global {
  function plausible(
    event: 'signup',
    details?: Partial<{
      callback: () => void;
      props: object;
      revenue: { currency: string; amount: number };
    }>,
  ): void;
}

if (!plausible) {
  window.plausible = (...args: unknown[]) => {
    console.log('[plausible]', ...args);
  };
}

const SENTRY_BLACKLISTED_HOSTNAMES = ['localhost', 'lvqconsult.ngrok.app'];

if (
  !isDevMode() &&
  !SENTRY_BLACKLISTED_HOSTNAMES.includes(window.location.hostname)
) {
  Sentry.init({
    dsn: 'https://860a5023ce873cdaca54c4fa64be4c06@o241437.ingest.sentry.io/4506356487421952',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['https://ng-todo.com'],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
