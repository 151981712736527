import { Injectable, isDevMode } from '@angular/core';

declare const __ngTodoLoggingEnabled: boolean;

function ngTodoLoggingEnabled() {
  try {
    if (location.href.indexOf('ngTodoLoggingEnabled') > -1) {
      return true;
    }

    return __ngTodoLoggingEnabled === true;
  } catch (e) {
    return false;
  }
}

@Injectable()
export class LoggerService
  implements Pick<Console, 'log' | 'info' | 'debug' | 'warn' | 'error'>
{
  log(...data: any[]): void {
    if (!this.shouldLog()) {
      return;
    }

    console.log(...data);
  }

  info(...data: any[]): void {
    if (!this.shouldLog()) {
      return;
    }

    console.info(...data);
  }

  debug(...data: any[]): void {
    if (!this.shouldLog()) {
      return;
    }

    console.debug(...data);
  }

  warn(...data: any[]): void {
    if (!this.shouldLog()) {
      return;
    }

    console.warn(...data);
  }

  error(...data: any[]): void {
    if (!this.shouldLog()) {
      return;
    }

    console.error(...data);
  }

  private shouldLog() {
    return isDevMode() || ngTodoLoggingEnabled();
  }
}
