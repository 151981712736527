import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';

import { setMe } from '../redux/app.actions';
import { MeModel } from '../types/me-mode.type';

@Injectable({ providedIn: 'root' })
export class ApiService {
  constructor(
    private httpClient: HttpClient,
    private store: Store,
  ) {}

  me() {
    return this.httpClient
      .get<MeModel>('/api/auth/me')
      .pipe(tap(me => this.store.dispatch(setMe({ me }))));
  }

  saveMe(me: { name: string }) {
    return this.httpClient.post<MeModel>('/api/auth/me', { name: me.name });
  }
}
