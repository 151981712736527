import { SnakeCaseToPascalCase } from '../types/snake-case-to-pascal-case.type';
import {
  BaseStreamEvent,
  StreamEvent,
  StreamEventMap,
  StreamEventTypes,
} from '../types/stream-event.type';

export type StreamEventFactories<Map extends StreamEventMap> = {
  [Type in keyof Map & string]: Map[Type] extends StreamEvent
    ? Map[Type]['payload'] extends null
      ? () => Map[Type]
      : (payload: Map[Type]['payload']) => Map[Type]
    : never;
};

export type AllStreamEventFactories = StreamEventFactories<StreamEventMap>;

function createStreamEventFactory<
  Event extends StreamEventTypes,
  Payload extends StreamEventMap[Event]['payload'],
  StreamEvent extends BaseStreamEvent<Event, Payload>,
>(event: Event): AllStreamEventFactories[Event] {
  return ((payload: Payload) =>
    ({
      type: event,
      payload,
    }) as StreamEvent) as unknown as AllStreamEventFactories[Event];
}

type ExportableStreamEventFactories = {
  [Type in keyof AllStreamEventFactories as `create${SnakeCaseToPascalCase<Type>}StreamEvent`]: AllStreamEventFactories[Type];
};

export const {
  createCreateTodoStreamEvent,
  createTodoCreatedStreamEvent,
  createUpdateTodoStreamEvent,
  createTodoUpdatedStreamEvent,
  createDeleteTodoStreamEvent,
  createTodoDeletedStreamEvent,
  createPingStreamEvent,
  createPongStreamEvent,
  createAllTodosRequestStreamEvent,
  createAllTodosResponseStreamEvent,
}: ExportableStreamEventFactories = {
  createCreateTodoStreamEvent: createStreamEventFactory('CREATE_TODO'),
  createTodoCreatedStreamEvent: createStreamEventFactory('TODO_CREATED'),
  createUpdateTodoStreamEvent: createStreamEventFactory('UPDATE_TODO'),
  createTodoUpdatedStreamEvent: createStreamEventFactory('TODO_UPDATED'),
  createDeleteTodoStreamEvent: createStreamEventFactory('DELETE_TODO'),
  createTodoDeletedStreamEvent: createStreamEventFactory('TODO_DELETED'),
  createPingStreamEvent: createStreamEventFactory('PING'),
  createPongStreamEvent: createStreamEventFactory('PONG'),
  createAllTodosRequestStreamEvent:
    createStreamEventFactory('ALL_TODOS_REQUEST'),
  createAllTodosResponseStreamEvent:
    createStreamEventFactory('ALL_TODOS_RESPONSE'),
};
