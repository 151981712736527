import { MeModel } from '../types/me-mode.type';
import { todoInitialState } from '../types/todo-model.type';

export const INITIAL_APP_STATE = {
  todos: todoInitialState,
  me: null as MeModel,
  hasTriedToAuthenticate: false as boolean,
  isSavingProfile: false as boolean,
} as const;

export type AppState = typeof INITIAL_APP_STATE;
