import { provideHttpClient } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  isDevMode,
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, Router } from '@angular/router';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { provideStore, Store } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import * as Sentry from '@sentry/angular-ivy';

import { routes } from './app.routes';
import { startApp } from './redux/app.actions';
import { AppEffects } from './redux/app.effects';
import { appReducer } from './redux/app.reducer';
import { LoggerService } from './services/logger.service';
import { StreamService } from './services/stream.service';

export function initializeApp(store: Store) {
  return async () => {
    store.dispatch(startApp());
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    LoggerService,
    StreamService,
    provideHttpClient(),
    provideAnimations(),
    provideRouter(routes),
    provideStore({ app: appReducer, router: routerReducer }),
    provideEffects(AppEffects),
    provideRouterStore(),
    provideStoreDevtools({ maxAge: 100, logOnly: !isDevMode() }),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [Store],
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
};
